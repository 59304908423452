
// Colors
$main-bg: #f7f7fc;
$primary: #2169DD;
$primary-light: color-mix(in oklch, transparent, $primary 70%);
$primary-dark: #052406;
$secondary: #077d0b;
$secondary-light: #009429;
$white: #ffffff;
$black: #000000;
$grey-light: #E3E5E8;
$grey-medium: #666666;
$grey-dark: #101928;
$divider: #D0DDF8;
$danger: #ff4747;
$danger-light: rgba(239, 54, 13, 0.1);
$success: #0cc558;
$success-light: rgba(204, 255, 225, 0.4);
$warning: #f4a229;
$warning-light: rgba(244, 162, 41, 0.15);
$orange: #ef790d;
$orange-light: rgba(239, 121, 13, 0.1);
$purple: #28147c;
$purple-light: rgba(113, 59, 219, 0.1);
$blue-dark: #07152C;
$nav-text-color: #666666;
$divider-color: #D0DDF8;
$bg-danger: #ef360d1a;
$blue-500: #07152C;

// Spacing
$gutter-size: 10px;
$header-size: 70px;
$footer-size: 50px;
$sidebar-size: 244px;
$logo-height: 30px;
$logo-width: 88px;

// Breakpoints
$S: 480px;
$SM: 575px;
$M: 768px;
$MD: 991px;
$L: 1024px;
$XL: 1200px;
$XXL: 1399px;

// Heights
$header-height: 4rem;
$mobile-header-height: 3.5rem;
$mobile-bottom-nav-height: 67px;