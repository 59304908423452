@use 'abstracts/breakpoints' as breakpoint;
@import 'abstracts/variables';

$primary: $primary;
$primary-light: $primary-light;
$primary-dark: $primary-dark;
$secondary: $secondary;
$success: #009429;
$danger: #dc4c64;
$warning: #ef790d;
$info: #54b4d3;
$light: #fbfbfb;
$dark: #333333;
$grey-light: #666666;

$seech-layout-width: 12.5rem;
$seech-layout-minimize-width: 3.5rem;
$seech-layout-item-space: 0;
$seech-layout-item-icon-right: 1rem;
$seech-layout-item-icon-left: 0;
$seech-layout-item-selected-overlay: $primary;
$seech-layout-bottom-nav-height: $mobile-bottom-nav-height;
$seech-layout-header-height: $header-height;
$seech-layout-mobile-header-height: $mobile-header-height;

seech-select {
  .sch-input {
    .sch-select-dropdown {
      min-height: auto;
    }
  }
}

.button-loader {
  seech-spinner {
    .loader {
      width: 1rem;
      height: 1rem;
    }
  }
}
