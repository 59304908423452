/* You can add global styles to this file, and also import other style files */
@import '@seech/core-ng/core';
@import '@seech/core-ng/theme';
@import '@seech/icons-ng';
@import 'theme';

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

.account-box {
  @apply bg-white border-[#E4E7EC] border-[1px] rounded-md;
}

.collapse {
  visibility: visible !important;
}
