@import url("https://fonts.googleapis.com/css2?family=Newsreader:ital,opsz,wght@0,6..72,200..800;1,6..72,200..800&display=swap");
ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

[class^=si-],
[class*=" si-"] {
  font-weight: 500 !important;
}

seech-select .sch-input .sch-select-dropdown {
  min-height: auto;
}

.button-loader seech-spinner .loader {
  width: 1rem;
  height: 1rem;
}

ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.mobile-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 82px;
  /* Adjust the height as needed */
  background-color: #ffffff;
  /* Background color for the nav */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  /* Optional shadow for elevation */
  display: flex;
  justify-content: space-around;
  /* Distribute items evenly */
  align-items: center;
  z-index: 1000;
  /* Ensure it stays on top of other elements */
}

*::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.2rem !important;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 0.25rem;
}
*:hover::-webkit-scrollbar-track {
  background: #f1f1f1;
}
*:hover::-webkit-scrollbar-thumb {
  background: #2169DD;
}

ul {
  list-style: none;
}

body {
  font-family: "Newsreader", serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}