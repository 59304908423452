@use 'sass:math';

@import 'variables';

@mixin styleFromBreakpoint($breakpoint) {
  @media only screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin styleBetweenBreakpoints($lowerbound, $upperbound) {
  @media only screen and (min-width: $lowerbound) and (max-width: $upperbound) {
    @content;
  }
}

@mixin pxToRem($size) {
  font-size: math.div($size, 16px) * 1rem !important;
}

@mixin setDistinctSize($width, $height) {
  width: $width;
  height: $height;
}

@mixin setRoundedCorner($size) {
  @include setDistinctSize($size, $size);
  border-radius: 50%;
}

@mixin custom-scrollbar($scrollbar-color) {
  &::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0.25rem;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollbar-color;
    }
  }
}

%no-gutter {
  margin: 0;
  padding: 0;
}

%divider-top {
  border: 1px $divider-color;
  border-style: solid none none none;
}

%divider {
  border: 1px $divider-color;
  border-style: none none solid none;
}

%no-divider {
  border-style: none none none none;
}

%search-edit {
  padding-right: calc(2% + 20px) !important;
  padding-left: 15px;
  border-radius: 100px;
  background-position: 98%;
  width: 100%;
  margin: 0 10px;
  transition: width 0.5s;
}

@mixin font($size: false, $color: false, $weight: false, $height: false) {
  @if $size {
    font-size: math.div($size, 16px) * 1rem;
  }

  @if $color {
    color: $color !important;
  }

  @if $weight {
    font-weight: $weight !important;
  }

  @if $height {
    line-height: $height !important;
  }
}

@mixin setSize($width: false, $height: false) {
  @if $width {
    width: $width;
  }

  @if $height {
    height: $height;
  }
}

@mixin roundEdge($size) {
  width: $size;
  height: $size;
  border-radius: $size;
}

@mixin styleToBreakpoint($breakpoint) {
  @media only screen and (max-width: $breakpoint) {
    @content;
  }
}

%hide-for-mobile {
  display: none;

  @include styleFromBreakpoint($S) {
    display: block;
  }
}

%hide-for-tablet {
  display: none;

  @include styleFromBreakpoint($M) {
    display: block;
  }
}