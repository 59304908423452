@import 'abstracts/mixins';

.mobile-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 82px;
  /* Adjust the height as needed */
  background-color: #ffffff;
  /* Background color for the nav */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  /* Optional shadow for elevation */
  display: flex;
  justify-content: space-around;
  /* Distribute items evenly */
  align-items: center;
  z-index: 1000;
  /* Ensure it stays on top of other elements */
}

* {
  @include custom-scrollbar($primary);
}

ul {
  list-style: none;
}

ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  @extend %no-gutter;
}
