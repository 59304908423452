@import '@seech/core-ng/base-theme';
@import 'abstracts/variables';

$light-default-theme: seech-light-theme($primary, $secondary);

.light {
  @include seech-theme-builder($light-default-theme);
}

.text-primary-light {
  color: $primary-light;
}

.dropdown-item {
  color: $primary;
  &:hover,
  &.active {
    background-color: $primary;
  }
}

//Money control
.money-input-control,
.money-dropdown {
  &:focus {
    border-color: $primary;
  }
  &[readonly='true'] {
    border-color: $primary;
  }
}

//Input
input.sch-input-field,
.sch-text-area {
  &:focus,
  .has-value {
    border-color: $primary;
  }
}

.sch-input {
  &[readonly='true'] {
    .sch-input-control,
    .sch-input-label,
    .text-wrapper {
      border-color: $primary-light;
      color: $primary-light !important;
    }
  }
}

input.sch-input-field,
.sch-text-area {
  // color: $primary-light;

  &:not([disabled]):not([ng-reflect-disabled='true'])[readonly] {
    color: $primary-light;
    border-color: $primary-light;
    &::placeholder {
      color: $primary-light !important;
    }
  }

  &.modern,
  &.hybrid {
    &:focus,
    .has-value {
      border-color: $primary;
    }

    &:focus + .sch-input-label,
    &:focus ~ .sch-input-icon {
      color: $primary;
      border-color: $primary;
    }
    &.has-value + .sch-input-label,
    &.has-placeholder + .sch-input-label {
      border-color: $primary-light;
    }
  }
  &.hybrid {
    &.has-value + .sch-input-label,
    &.has-placeholder + .sch-input-label {
      border-color: #97a2b0;
    }
  }
}

.sch-input-label {
  // color: $primary;
  &:not(.classic) {
    @extend .mdb-bg;
  }
  &[readonly='true'] {
    color: $primary-light;
  }
  &[disabled='true'] {
    color: #48525f;
  }
}

.light button.btn-primary {
  color: #fff !important;
}
